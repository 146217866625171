<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      :loading="loading"
      width="width:45rem"
      padding="padding:0 1.2rem"
      @submit="handleSubmit(submit)"
      submit="Update"
      :disabled-button="forms.length < 1"
      @close="$emit('close'), (forms = [])"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple capitalize">
          {{ `Edit ${modalType}` }}
        </p>
      </template>
      <div>
        <table aria-label="table" class=" w-full mt-4">
          <thead>
            <tr>
              <th
                class=" uppercase"
                id="header"
                v-for="(header, index) in headers"
                :key="index"
              >
                <p>{{ header.title }}</p>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, i) in forms"
              :key="i"
              class="tableBody text-sm "
              style="height: 48px; "
            >
              <td class="w-1/4">
                <div
                  class="wrapper disabled disabled_hideOutline"
                  v-tooltip.bottom-start="data.categorySlug"
                  v-if="data.isDefault"
                >
                  {{ data.categorySlug }}
                </div>
                <c-select
                  v-else
                  :options="
                    modalType === 'addition'
                      ? additionCategoryItems
                      : deductionCategoryItems
                  "
                  variant="w-full capitalize"
                  v-model="data.categorySlug"
                />
              </td>
              <td class="w-1/4">
                <div
                  class="wrapper disabled disabled_hideOutline"
                  v-tooltip.bottom-start="data.name"
                  v-if="data.isDefault"
                >
                  {{ data.name }}
                </div>
                <c-text v-else placeholder="--Enter--" v-model="data.name" />
              </td>
              <td class="w-1/4">
                <div
                  class="wrapper disabled disabled_hideOutline"
                  v-if="data.isDefault"
                >
                  {{ data.isTaxable === "true" ? "Yes" : "No" }}
                </div>
                <c-select
                  v-else
                  :options="taxableOptions"
                  :disabled="data.categorySlug === 'reimbursement'"
                  variant="w-full"
                  v-model="data.isTaxable"
                />
              </td>
              <td class="w-1/4">
                <div
                  class="wrapper disabled disabled_hideOutline"
                  v-if="data.isDefault"
                >
                  {{ data.amount }}
                </div>
                <c-text
                  v-else
                  placeholder="--Enter--"
                  type="number"
                  v-model="data.amount"
                />
              </td>
              <td v-if="!data.isDefault">
                <Icon
                  size="s"
                  @click.native="deleteAnItem(data, i)"
                  class="text-flame cursor-pointer"
                  icon-name="close"
                />
              </td>
            </tr>
            <tr class="h-7" />
            <tr
              style="height: 52px; border-spacing: 15px 0;background: #F7F7FF !important;"
              class="mt-4 bg-ghostWhite"
              v-if="forms.length > 0"
            >
              <td class="font-bold text-lg text-darkPurple ">
                Total
              </td>
              <td />
              <td />
              <td class="font-bold">
                {{ convertToCurrency(totalAmount) }}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </RightSideBar>
    <Modal v-if="openDelete">
      <Card class=" p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold  ml-4 my-5 text-center">
            Are you sure you want to delete this {{ modalType }}?
          </p>
          <div class="flex ">
            <Button class="text-white" @click="deleteItem"
              :background="appearance.buttonColor || defaultBTNColor"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4 "
              @click="openDelete = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  name: "FilterPayrun",
  components: {
    ValidationObserver,
    RightSideBar: () => import("@/components/RightSideBar"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    Modal: () => import("@/components/Modal"),
    Card: () => import("@/components/Card"),
    Button: () => import("@/components/Button"),
    CText: () => import("@scelloo/cloudenly-ui/src/components/text")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: String,
      default: "addition"
    },
    userId: {
      type: String,
      default: ""
    },
    month: {
      type: String,
      default: ""
    },
    year: {
      type: String,
      default: ""
    },
    payItemSource: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      forms: [],
      openDelete: false,
      itemId: "",
      itemIndex: 0,
      taxableOptions: [
        { name: "Yes", id: "true" },
        { name: "No", id: "false" }
      ],
      additionCategoryItems: ["commission", "reimbursement", "bonus", "others"],
      deductionCategoryItems: ["penalty", "others"],
      headers: [
        { title: "Category" },
        { title: "Name in Payslip" },
        { title: "Taxable" },
        { title: "amount(₦)" }
      ],
      loading: true
    };
  },
  watch: {
    openModal() {
      if (this.openModal) {
        this.getAdditions();
      }
    }
  },
  computed: {
    totalAmount: {
      get() {
        let result = 0;
        this.forms.forEach(item => {
          result += Number(item.amount);
        });
        return result;
      },
      set() {}
    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
    deleteItem() {
      this.$_deleteAdditionsDeductions(this.itemId)
        .then(() => {
          this.forms = [];
          this.loading = true;
          this.getAdditions();
          this.openDelete = false;
          this.removeForm(this.itemIndex);
          this.itemId = "";
          this.itemIndex = 0;
          this.$toasted.success(`${this.modalType} deleted successfully`, {
            duration: 5000
          });
        })
        .catch(() => {
          this.$toasted.error("An error occured", { duration: 5000 });
        });
    },
    deleteAnItem(item, index) {
      this.openDelete = true;
      this.itemId = item.id;
      this.itemIndex = index;
    },
    async getAdditions() {
      this.loading = true;
      let month; let year;
      let offcyceModalType = this.modalType + "&payFlowType=offcycle";

      if (this.month === "" && this.year === "") {
        if (this.$route.query) {
          month = this.$route.query.month;
          year = this.$route.query.year;
        }
        else if (this.$route.params.month) {
          month = this.$route.params.month;
          year = this.$route.params.year
        }
      }
      else {
        month = this.month;
        year = this.year;
      }

      try {
        const { data } = await this.$_getAdditionsDeductions({
          month,
          year,
          userId: this.userId,
          type: this.payItemSource === "off-cycle" ? offcyceModalType : this.modalType
        });
        const result = data.data;
        this.totalAmount = result.totalAmount;

        result.forEach(element => {
          this.forms.push({
            categorySlug: element.categorySlug,
            isDefault: element.isDefault,
            name: element.name,
            isTaxable: element.isTaxable.toString(),
            amount: element.amount,
            id: element.id
          });
        });
        this.loading = false;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    removeForm(index) {
      if (index > 0) this.forms.splice(index, 1);
    },
    submit() {
      const payload = [];
      this.forms.forEach(item => {
        if (!item.isDefault) {
          payload.push({
            categorySlug: item.categorySlug,
            name: item.name,
            categoryId: item.id,
            type: this.modalType,
            id: item.id,
            amount: item.amount,
            isTaxable: item.isTaxable === "true"
          });
        }
      });
      this.$_editAdditionsDeductions(payload)
        .then(() => {
          this.$emit("close");
          this.forms = [];
          this.$toasted.success(`${this.modalType} updated successfully`, {
            duration: 3000
          });
        })
        .catch(error => {
          this.$toasted.error(`${this.modalType}  not updated successfully`, {
            duration: 5000
          });
          throw new Error(error);
        });
    }
  }
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: white;
}
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
